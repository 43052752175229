import { assemble, chain, isGreaterThanOrEqual, isNumeric, isRequired } from '../../../utils/i18nValidators';
import { validator } from 'simple-object-validation';

import container from '../../../container';
import { claimFileType } from '../../claims-center/take-action/claimFileTypeEnum';

function hasFileId(file) {
  return !!file.response?.body?.fileId;
}

const fileIsRequired = validator(
  (files) => !!files?.length && files.every(hasFileId),
  (param, name) => {
    const translatedName = container.i18n.t(name);
    return container.i18n.t('{{name}} is required', { name: translatedName });
  }
);

const fileIsRequiredIf = (condition, fileName) => {
  if (condition) {
    return fileIsRequired(fileName);
  }

  return () => undefined;
};

const weightIsRequired = validator(
  (value) => isNumeric(value) && value > 0,
  (param, name) => {
    const translatedName = container.i18n.t(name);
    return container.i18n.t('{{name}} is required', { name: translatedName });
  }
);

const maxChars = validator((string, length) => !string || string.length < length, {
  expectParameter: true,
  messageCreator: (length, name) => {
    const translatedName = container.i18n.t(name);
    return container.i18n.t('{{name}} must be smaller than {{length}} characters', {
      name: translatedName,
      length,
    });
  },
});

const trackingInfoValidation = {
  trackingCode: isRequired('Tracking ID'),
};

export const trackingInfoValidator = assemble(trackingInfoValidation);

const shippingInfoValidation = {
  trackingCode: isRequired('Tracking ID'),
  recipientName: isRequired('Customer name'),
  recipientAddress: isRequired('Recipient address'),
  recipientCity: isRequired('Recipient city'),
  recipientZipCode: isRequired('Recipient zip code'),
  recipientCountryCode: isRequired('Recipient country'),
  weight: weightIsRequired('Weight'),
};

export const shippingInfoValidator = assemble(shippingInfoValidation);

export const shippingInfoValidatorWithTrackingScreenshot = assemble({
  ...shippingInfoValidation,
  trackingScreenshot: fileIsRequired('Tracking screenshot'),
});

const warehouseDeliveryConfirmationInfoValidation = {
  warehouseDeliveryDate: isRequired('Warehouse delivery date'),
};

export const warehouseDeliveryConfirmationInfoValidator = assemble(warehouseDeliveryConfirmationInfoValidation);

export const invoiceInfoValidator = assemble({
  netValue: chain([isRequired, isNumeric, isGreaterThanOrEqual(0)])('Net value'),
  invoiceDocuments: fileIsRequired('Invoice documents'),
  shopClaimId: maxChars(64)('Internal claim ID'),
});

export const wholeDamageInfoValidator = assemble({
  wholeDamageInfo: isRequired('Whole damage info'),
  picturesOfDamage: fileIsRequired('Pictures of damage'),
  lmcCaseId: maxChars(64)('Carrier case ID'),
});

export function isEmptyValidationResult(result) {
  return result && Object.keys(result).length === 0;
}

export function getFirstValidationError(result) {
  const keys = Object.keys(result);

  if (keys.length === 0) {
    return null;
  }

  const error = result[keys[0]];

  // if error isn't a string, e.g. {foo: { bar: 'message' }} we go deeper
  return typeof error === 'string' ? error : getFirstValidationError(error);
}

function damageInfoValidator(value) {
  if (!Array.isArray(value)) {
    return 'Damage info must be an array';
  }

  if (value.length === 0) {
    return 'Damage info can not be empty';
  }

  const shapeValidator = assemble({
    amount: chain([isRequired, isNumeric, isGreaterThanOrEqual(1)])('Amount'),
    itemName: isRequired('Item name'),
    damageDescription: isRequired('Demage description'),
  });

  const results = {};

  value.forEach((info, index) => {
    const result = shapeValidator(info);

    if (!isEmptyValidationResult(result)) {
      results[info.id] = result;
    }
  });

  return isEmptyValidationResult(results) ? undefined : results;
}

export const partialDamageInfoValidator = assemble({
  partialDamageInfo: damageInfoValidator,
  picturesOfDamage: fileIsRequired('Pictures of damage'),
  lmcCaseId: maxChars(64)('Carrier case ID'),
});

function missingItemValidator(value) {
  if (!Array.isArray(value)) {
    return 'Missing item info must be an array';
  }

  if (value.length === 0) {
    return 'Missing item info can not be empty';
  }

  const shapeValidator = assemble({
    amount: chain([isRequired, isNumeric, isGreaterThanOrEqual(1)])('Amount'),
    itemName: isRequired('Item name'),
  });

  const results = {};

  value.forEach((info, index) => {
    const result = shapeValidator(info);

    if (!isEmptyValidationResult(result)) {
      results[info.id] = result;
    }
  });

  return isEmptyValidationResult(results) ? undefined : results;
}

export const createMissingItemInfoValidator = ({ isPicturesOfMissingItemRequired }) =>
  assemble({
    missingItemInfo: missingItemValidator,
    picturesOfMissingItem: fileIsRequiredIf(isPicturesOfMissingItemRequired, 'Pictures of missing item'),
    lmcCaseId: maxChars(64)('Carrier case ID'),
  });

/*
 * @param {object} data
 * @param {string} [data.comment]
 * @param {array} [UploaderFile]
 */
export function takeActionValidator(data) {
  const { comment, files = [] } = data;

  if (!comment && files.length === 0) {
    return {
      comment: 'CLAIM_TAKE_ACTION_COMMENT_OR_FILE_IS_REQUIRED',
    };
  }

  if (files.length > 0) {
    const singleFileAllowedFileTypes = [
      claimFileType.TRACKING_SCREENSHOT,
      claimFileType.INVOICE_DOCUMENT,
      claimFileType.DECLARATION_OF_RECIPIENT,
    ];
    const errorsByFileId = {};
    const fileTypeOccurrences = {};

    for (const file of files) {
      const { fileType } = file;
      fileTypeOccurrences[fileType] = fileTypeOccurrences[fileType] ? fileTypeOccurrences[fileType] + 1 : 1;

      if (!file.fileType) {
        errorsByFileId[file.id] = 'CLAIM_TAKE_ACTION_FILE_TYPE_IS_REQUIRED';
      } else if (singleFileAllowedFileTypes.includes(fileType) && fileTypeOccurrences[fileType] > 1) {
        errorsByFileId[file.id] = 'CLAIM_TAKE_ACTION_TOO_MANY_FILES_OF_SAME_TYPE';
      }
    }

    if (!isEmptyValidationResult(errorsByFileId)) {
      return { files: errorsByFileId };
    }
  }

  return {};
}

export const deadlineValidator = assemble({
  outsideOfDeadlineReason: isRequired('Reason why'),
});
